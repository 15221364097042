/* eslint-disable prettier/prettier */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/no-all-duplicated-branches */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable no-var */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable prettier/prettier */

/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect } from 'react';

import {
  getSalesLead,
  getSalesLeadInvenventoryLambda,
  getCustomerForsalesLead,
  UpdateCustomerForsalesLead,
  updateTransferReceiveDetails
} from '../../api/user';
import { ReactComponent as Expandicon } from '../../assets/images/fullscreen-mode.svg';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/No-records.svg';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { fieldDeviceStyles } from '../fieldDeviceStyles';
import {
  RACTable,
  RACTableRow,
  RACTableCell,
  CircularProgress,
  RACModalCard,
  RACButton,
  Grid,
  Card,
  CardContent,
  Typography,
} from '@rentacenter/racstrap';
import { RACCOLOR, makeStyles } from '@rentacenter/racstrap';
import { Chip } from '@material-ui/core';
import { FeatureFlagContext } from '../../context/featureFlagContext';



//UI
const useClasses = makeStyles((theme: any) => ({
  racpadSubheader: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '10px',
    },

    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(189, 189, 196)',

      borderRadius: '20px',

      border: '3px solid rgb(206, 204, 201)',
    },

    '*::-webkit-scrollbar-track': {
      background: 'rgb(245, 243, 239)',
    },
  },
  listgroupitem: {
    width: '100%!important',
    padding: theme.typography.pxToRem(2),
  },
  padding1: {
    paddingRight: '0.25rem',
    paddingLeft: '0.25rem',
  },
  RACcardsales: {
    borderRadius: theme.typography.pxToRem(16),
    boxShadow: '0px 1px 4px 0px  #0000013',
    position: 'relative',
    maxHeight: theme.typography.pxToRem(303),

    '&:hover': {
      '& $RACexpandIcon': {
        display: 'block',
      },
    },
  },
  RACcardHeight: {
    height: theme.typography.pxToRem(303),
  },
  mb3: {
    marginBottom: theme.typography.pxToRem(16),
  },

  title: {
    color: '#212529',
    fontFamily: 'OpenSans-bold',
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(10),
  },
  spaceTitle: {
    marginBottom: theme.typography.pxToRem(0),
    paddingBottom: theme.typography.pxToRem(0),
  },
  RACloaderNorecords: {
    textAlign: 'center',
    marginTop: theme.typography.pxToRem(75),
    fontSize: theme.typography.pxToRem(16),
    color: `${RACCOLOR.RICH_BLACK}`,
    fontWeight: theme.typography.pxToRem(580),
    marginLeft: theme.typography.pxToRem(30),
    marginRight: theme.typography.pxToRem(30),
  },

  textcenter: {
    textAlign: 'center',
  },
  w100: {
    width: '100%',
  },
  textendHead: {
    textAlign: 'left',
    color: '#84899A',
    whiteSpace: 'nowrap',
  },
  background: {
    backgroundColor: `${RACCOLOR.WHITE}`,
  },
  colorHead: {
    color: '#84899A',
    fontFamily: 'OpenSans-semibold',
    whiteSpace: 'nowrap',
  },
  tablerow1: {
    backgroundColor: '#fff',
    cursor: 'pointer',
  },
  tablebggreen: {
    backgroundColor: '#F0FDF5',
  },
  tdstyle: {
    position: 'relative',

    fontFamily: 'OpenSans-semibold',
  },
  commonRow: {
    '&:hover': {
      backgroundColor: '#ececec',
      cursor: 'pointer',
    },
  },
  textend: {
    textAlign: 'right',
  },
  racpaddashboardlinks: {
    borderRadius: theme.typography.pxToRem(2),
    borderTopRightRadius: theme.typography.pxToRem(2),
    borderBottomRightRadius: theme.typography.pxToRem(2),
    position: 'absolute',
    left: theme.typography.pxToRem(0),
    top: '9%',
    height: theme.typography.pxToRem(42),
    bottom: '50%',
  },
  racpadbrgreen: {
    borderLeft: '3px solid #1CD565 !important',
    cursor: 'pointer',
  },
  racpadbrleftmsgs: {
    borderLeft: '4px solid #2E31BE !important',
  },
  tablebgblues: {
    backgroundColor: '#EBF3FF !important',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)!important',
    },
  },
  fixedheadertableonline: {
    overflowY: 'auto',
    maxHeight: theme.typography.pxToRem(235),
  },
  RACexpandIcon: {
    width: theme.typography.pxToRem(22),
    height: theme.typography.pxToRem(22),
    right: theme.typography.pxToRem(4),
    top: theme.typography.pxToRem(5),
    position: 'absolute',
    cursor: 'pointer',
    display: 'none',
  },
  racErrorIcon: {
    width: '50px',
    // height: '30px',
    // marginRight: '5px',
  },
  textSpaceNorecords: {
    textAlign: 'center',
    width: '100%',
  },
  margin15: {
    margin: theme.typography.pxToRem(15),
  },
  RACexpandIconclose: {
    width: theme.typography.pxToRem(22),
    height: theme.typography.pxToRem(22),
    right: theme.typography.pxToRem(4),
    top: theme.typography.pxToRem(5),
    position: 'absolute',
    cursor: 'pointer',
  },
  fixedheadertableonline1: {
    overflowY: 'auto',
    maxHeight: theme.typography.pxToRem(450),
  },
  RACcardHeightModal: {
    paddingTop: theme.typography.pxToRem(0),
    paddingBottom: theme.typography.pxToRem(0),
    paddingLeft: theme.typography.pxToRem(15),
    paddingRight: theme.typography.pxToRem(15),
  },

  textcenterNorecords: {
    textAlign: 'center',
    marginTop: theme.typography.pxToRem(60),
    width: '100%',
    fontFamily: 'OpenSans-regular',
    fontSize: theme.typography.pxToRem(14),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textcenterImage: {
    textAlign: 'center',
    marginTop: theme.typography.pxToRem(20),
    width: '100%',
    fontFamily: 'OpenSans-semibold',
    color: '#A5A6A6',
    fontSize: theme.typography.pxToRem(14),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alerticon: {
    paddingRight: theme.typography.pxToRem(10),
    paddingTop: theme.typography.pxToRem(8),
  },
  RACloaderPage: {
    textAlign: 'center',
    marginTop: theme.typography.pxToRem(250),
    marginLeft: theme.typography.pxToRem(30),
    marginRight: theme.typography.pxToRem(30),
  },
  btnlocation: {
    marginLeft: theme.typography.pxToRem(238),
    marginTop: theme.typography.pxToRem(24),
    marginBottom: theme.typography.pxToRem(8),
  },
  modal: {
    '&:focus-visible': {
      outline: theme.typography.pxToRem(0),
    },
  },
  popuptitle: {
    width: 'auto',
    overFlowY: 'auto',
  },

  mt2: {
    marginTop: theme.typography.pxToRem(8),
  },
  card: {
    borderRadius: theme.typography.pxToRem(16),
    boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
    width: '100%',
  },
  px2: {
    paddingRight: theme.typography.pxToRem(8),
    paddingLeft: theme.typography.pxToRem(8),
  },
  mt3: {
    marginTop: theme.typography.pxToRem(16),
  },
  RACcardlg12: {
    flex: '0 0 auto',

    width: '100%',
  },
  popupTextStyle: {
    fontFamily: 'OpenSans-semibold',
    color: 'grey',
  },
  mb1: {
    marginBottom: theme.typography.pxToRem(4),
  },
  mb2: {
    marginBottom: theme.typography.pxToRem(8),
  },
  racLoader: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, 85%)',
    transition: 'opacity 0.15s linear',
  },
  textcenterNorecordsSomething: {
    textAlign: 'center',
    marginTop: theme.typography.pxToRem(90),
    width: '100%',
    fontFamily: 'OpenSans-semibold',
    color: '#A5A6A6',

    fontSize: theme.typography.pxToRem(14),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  masterLoader: {
    position: 'fixed',
    backgroundColor: `${RACCOLOR.WASHED_BLACK}`,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    opacity: 0.6,
    zIndex: 1050,
    textAlign: 'center',
    margin: 0,
  },
  Loader: {
    display: 'block',
    position: 'fixed',
    zIndex: 1051,
    top: '50%',
    right: '50%',
  },
  ms1: {
    marginLeft: theme.typography.pxToRem(4),
  },
  me1: {
    marginRight: theme.typography.pxToRem(4),
  },
}));

export default function Gridcomponent() {
  const classes1 = fieldDeviceStyles();
  const classes = useClasses();
  const history = useHistory();
  const [expandSale, setExpandSale] = React.useState(false);
  const [popupUnreserved1, setUnreserved1] = React.useState(false);
  const [popupUnreserved2, setUnreserved2] = React.useState(false);
  const [transferPopup, setTransferPopup] = React.useState(false);
  const [AppprovalPopup, setAppprovalPopup] = React.useState(false);
    const [geopoolinventorydata, setgeopoolinventorydata] = React.useState<any>();
  const [customerDetails, setCustomerDetails] = React.useState({
    customerId: '',
    saleLeadId: '',
    globalcustomerId: '',
    webleadPaymentId: ''
  });
  const [inventorynum, setinventorynum] = React.useState('');
  const [modelNumber, setModelnum] = React.useState('');
  const [Gcid, setGcid] = React.useState('');
  const [CustomerId, setCustomerId] = React.useState('')
  const [webleadPaymentId, setwebleadPaymentId] = React.useState('')
  //workPhoneNumber
  const [workPhoneNumber, setworkPhoneNumber] = React.useState('');
  //neighbouringStoreNumber
  const [neighbouringStoreNumber, setneighbouringStoreNumber] =
    React.useState('');

    const [loaderforgeopool, setloaderforgeopool] = React.useState(false);
    const { customerVerification } = useContext(FeatureFlagContext);


  let salesLeadInfo: any = [{}];
  let storeDetailsInfo: any = '';

    let obj1
  const [masterLoader, setloader] = React.useState(false);
  const [loader1, setloader1] = React.useState(true);

  const [salesLeadData, setSalesLeadData] = React.useState([]);

  const [leadId, setLeadId] = React.useState('');
  const [mappedData, setMappedData] = React.useState<any[]>([]);
  const [webLeadData, setWebLeadData] = React.useState<any[]>([]);
  const [Status, setStatus] = React.useState('');

  const [Res, setstateRes] = React.useState<any[]>([]);


  useEffect(() => {
    const fetch = async () => {
      // setloader(true);
      const dateFormat = new Date().toLocaleDateString();
      const currentDate = moment(dateFormat).format('YYYY/MM/DD');
      // payload for salesLead
      const storeNumber = window.sessionStorage.getItem('storeNumber')
      if (storeNumber) {
        const paramOnlineTransactionsalesLead = {
          //salesLead expiration Sales_Lead_Expiration_Days
          paramKeyNames: ['Sales_Lead_Expiration_Days'],
          storeNumbers: [storeNumber],
          startDate: currentDate,
          endDate: currentDate,
          leadSource: ['Web'],
          leadStatues: [
            'New',
            'Text Sent',
            'Call Made',
            'Commitment',
            'Follow-Up',
            'Transferred from Agent'
          ],
          numberOfDays: 30,
          escalateOrderFlag: 0,
          includeConversion: 1,
        };

        const salesLeadRes: any = await getSalesLead(
          paramOnlineTransactionsalesLead
        );

        console.log('salesLeadRes', salesLeadRes);
        setloader1(false);

        let sortingSaleslead: any = [];
        //Sales Lead and Web Lead Combined Response
        if (
          salesLeadRes.data !== undefined &&
          salesLeadRes.status === 200 &&
          salesLeadRes.data.length !== 0 &&
          salesLeadRes.data.length !== []
        ) {
          if (
            salesLeadRes.data.GetWebLead.length === 0 ||
            salesLeadRes.data.GetWebLead === [] ||
            salesLeadRes.data.GetWebLead.value === ''
          ) {
            if (
              salesLeadRes.data.salesLead.value == [] ||
              salesLeadRes.data.salesLead.value == ''
            ) {
              setSalesLeadData([]);
            } else {
              sortingSaleslead = salesLeadRes.data.salesLead.value.leads.sort(
                (a: any, b: any) => (a.createdDate > b.createdDate ? -1 : 1)
              );

              setMappedData(sortingSaleslead ? sortingSaleslead : []);
            }
          } else if (
            salesLeadRes.data.GetWebLead !== null ||
            salesLeadRes.data.GetWebLead !== [] ||
            salesLeadRes.data.GetWebLead.value !== '' ||
            salesLeadRes.data.GetWebLead.value.length !== 0
          ) {
            const res =
              salesLeadRes.data.GetWebLead.value.webleads.map(
                (x: any) =>
                  Object.assign(
                    x,
                    salesLeadRes.data.salesLead.value.leads.find(
                    (y: any) => y.webleadPaymentId == x.orderNumber || x.entWebLeadPaymentId && (y.webleadPaymentId == x.entWebLeadPaymentId.toString())
                  )
                )
            );

            const webLead =
              salesLeadRes.data.GetWebLead.value.webleads;

            setWebLeadData(webLead);
            console.log('webLead: ', webLead);

            setstateRes(res);

            if (
              salesLeadRes.data.salesLead.value == [] ||
              salesLeadRes.data.salesLead.value == ''
            ) {
              setSalesLeadData([]);
            } else {
              sortingSaleslead = salesLeadRes.data.salesLead.value.leads.sort(
                (a: any, b: any) => (a.createdDate > b.createdDate ? -1 : 1)
              );

              setMappedData(sortingSaleslead ? sortingSaleslead : []);
            }
          }
        }
        setStatus(salesLeadRes.status.toString());
      }
    };
    fetch();

  }, []);

  const cancelPopUp = () => {
    setExpandSale(false);
    setAppprovalPopup(false);
    setUnreserved1(false);
    setTransferPopup(false);
    setUnreserved2(false);
  };

  const navMenuNavigation = () => {
    if (customerDetails.customerId !== '') {
      history.push(`/agreement/rental/itemsearch/${customerDetails.customerId}?salesLeadId=${customerDetails.saleLeadId}`);

    } else {
      history.push({
        pathname: `/customer/createcustomer`,
        search: `?salesLeadId=${customerDetails.saleLeadId}&gcid=${customerDetails.saleLeadId}`,
        state: {
          wleadPaymentId: customerDetails.webleadPaymentId,
        },
      })
    }
  }
    const okUnreservedPopuphomestore = () => {
      navMenuNavigation();
      setUnreserved1(false);
    };
//salesLead inventory popups Ok BUTTON redirection
  const okUnreservedPopup2 = () => {
    //redirect to accept  inventory transfer receive page by passing search type(radius),inventory#,model#
    // setUnreserved2(false);
    console.log('Customer Object', customerDetails);
    if (customerDetails.customerId !== '') {
      history.push(`/agreement/rental/itemsearch/${customerDetails.customerId}?salesLeadId=${customerDetails.saleLeadId}`);

    } else {
      history.push({
        pathname: `/customer/createcustomer`,
        search: `?salesLeadId=${customerDetails.saleLeadId}&gcid=${customerDetails.saleLeadId}`,
        state: {
          wleadPaymentId: customerDetails.webleadPaymentId,
        },
      })
    }
    //history.push(`/inventory/inventorysearch?modelno=${modelNumber}`)
  };
    const okUnreservedPopup1 = async () => {
      debugger
      setUnreserved1(false);
      setloader(true)
      if (inventorynum != undefined && inventorynum != null && inventorynum != '') {
        const request = {
          currentStoreNumber: window.sessionStorage.getItem('storeNumber'),
          inventoryNumbers: [inventorynum],
          isRejected: false,
          reason: "sample"
        }
        console.log('payload for inventory', request)
        const respData: any = await updateTransferReceiveDetails(request);

        if (respData.status == 200) {
          setloader(false)
          navMenuNavigation()
          setUnreserved1(false);
        }
        else {
          setloader(false)
          UnreservedPopupChildrenSmall();
        }
      }
    };

 const ok_Transfer_Popup = async () => {
    setTransferPopup(false);
    //  history.push (`http://localhost:3002/accept_inventory transfer receive/`);
  };
  const ok_approval_Popup = () => {
    CustomerId === null || CustomerId === undefined ?
      // history.push(`/customer/createcustomer?salesLeadId=${leadId}&gcid=${Gcid}`)
      history.push({
        pathname: `/customer/createcustomer`,
        search: `?salesLeadId=${leadId}&gcid=${Gcid}`,
        state: {
          wleadPaymentId: webleadPaymentId,
        },
      })
      : history.push(`/customer/update/${CustomerId}/customer`);
    //setAppprovalPopup(false);
  };

  /*************************************** SALES LEAD -STARTS HERE ********************************************/
  /*****************************SalesLead header part binding****************/

  const temp = [
    {
      display: 'Last Name',
    },
    {
      display: 'First Name',
    },
    {
      display: 'Date',
    },
    {
      display: 'Phone Number',
    },
    {
      display: 'Product',
    },
    {
      display: 'Description',
    },
    {
      display: 'WL Deposit',
    },
    {
      display: 'Type',
    },
    {
      display: 'Order ID',
    },
    ...(customerVerification == 1 ? [{ display: 'Customer Type' }] : []),
    {
      display: 'Notes',
    },
  ];

  const buildSalesLeadHead = () => (
    <>
      {tempSalesLead.map((obj: any) => {
        return (
          <RACTableCell
            key={obj.display}
            className={`${classes.colorHead} ${classes.background}`}
          >
            {' '}
            {obj.display}{' '}
          </RACTableCell>
        );
      })}
    </>
  );

  const buildExpandSalesLeadHeadPopup = () => (
    <>

      {temp.map((obj: any) => {
        return (
          <RACTableCell
            key={obj.display}
            className={`${classes.textendHead} ${classes.background}`}
          >
            {' '}
            {obj.display}{' '}
          </RACTableCell>
        );
      })}
    </>
  );

  const buildConvertedData = () => (
    <>
      {mappedData.map((obj: any, index: any) => {
        /* Get the Web Lead Details for the given matching Order ID */
        let webLeadInfo: any = {};

        if (obj.webleadPaymentId != null) {
          webLeadInfo = webLeadData.filter(
            (val) =>  val.orderNumber === obj.webleadPaymentId || val.entWebLeadPaymentId && (val.entWebLeadPaymentId.toString() === obj.webleadPaymentId)
          );
        }

        const inventoryNumber = obj.inventories.length <= 0 || obj.inventories === undefined ? obj.inventoryNumber : obj.inventories[0].inventoryNumber;
        const modelNumber = obj.inventories.length <= 0 || obj.inventories === undefined ? obj.modelNumber : obj.inventories[0].modelNumber;
        const typeReserved = 'Reserved';

        const typeCustomerOrder = 'Customer Order';
        const typeReimbursed = 'Reimbursed';

        const c = new Date(obj.createdDate);

        const time1 = c.toISOString().split('T')[0];

        const dateFormat = moment(time1).format('MM/DD/YYYY');
        const num =
          obj.mobilePhoneNumber !== null && obj.mobilePhoneNumber !== undefined
            ? obj.mobilePhoneNumber
            : '-';
        const phoneRegex1 =
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

        const formatmobileNum =
          obj.mobilePhoneNumber !== null && obj.mobilePhoneNumber !== undefined
            ?
            num.replace(phoneRegex1, '($1) $2-$3')
            : '-';

        const leadConvertedDate =
          obj.leadConvertedDate !== null && obj.leadConvertedDate !== undefined
            ? obj.leadConvertedDate
            : '-';
        const now = +new Date();
        const createdAt = +new Date(Date.parse(leadConvertedDate.toString()));

        const oneDay = 24 * 60 * 60 * 1000;
        const notesColumn = obj.leadSummary === null ? '-' : obj.leadSummary;
        const type =
          Object.keys(webLeadInfo).length > 0 &&
            (webLeadInfo[0].status == 'PA' ||
              webLeadInfo[0].status == 'PR' ||
              webLeadInfo[0].status == 'REFUND')
            ? typeReimbursed
            : obj.webleadPaymentId !== null &&
              inventoryNumber !== null
              ? typeReserved
              : (modelNumber !== '' && modelNumber !== null) &&
                inventoryNumber == null
                ? typeCustomerOrder
                : '-';
        const product =
          type == typeReserved
            ? inventoryNumber
            : type == typeCustomerOrder
              ? modelNumber
              : type == '-' && (modelNumber !== '' && modelNumber !== null)
                ? modelNumber
                : '-';
        /* The above code is using destructuring assignment to extract the `customerType` and `colorId`
        properties from the object returned by the `getCustomerType` function. This allows you to
        access these properties directly without having to use the object's full name. */
        const { customerType, colorId } = getCustomerType(obj);

        /*** CONVERTED CONDITION ***/
        if (
          now - createdAt < oneDay &&
          obj.leadStatus === 'Converted' &&
          type !== typeReimbursed
        ) {
          const leadDeposit =
            obj.webleadPaymentId !== null
              ? webLeadInfo[0]?.paymentInfo?.balanceAmount !== 0
                ? '$'.concat(webLeadInfo[0]?.paymentInfo?.balanceAmount)
                : '-'
              : obj.webleadPaymentId != null ? obj.downPaymentAmount !== 0
                ? '$'.concat(obj.downPaymentAmount)
                : '-' : '-';

          return (
            <RACTableRow
              className={`${classes.tablerow1}  ${classes.tablebggreen}`}
              title="Converted"
              key={index}
              hover
              backgroundColor={'white'}
            >
              <RACTableCell key={obj.firstName} className={classes.tdstyle}>
                <Typography
                  variant="caption"
                  className={`${classes.racpaddashboardlinks} ${classes.racpadbrgreen}`}
                ></Typography>
                {obj.lastName}
              </RACTableCell>
              <RACTableCell key={obj.firstName} className={classes.tdstyle}>
                <Typography
                  variant="caption"
                  className={`${classes.racpaddashboardlinks}`}
                ></Typography>
                {obj.firstName}
              </RACTableCell>
              <RACTableCell key={obj.date} className={classes.tdstyle}>
                {dateFormat}
              </RACTableCell>
              <RACTableCell
                key={obj.mobilePhoneNumber}
                className={classes.tdstyle}
              >
                {formatmobileNum}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                title={product !== null && product !== undefined ? product.length > 13 ? product : null : null}
                key={inventoryNumber}
              >
                {product !== null && product !== undefined ? product.length > 13
                  ? product.substring(0, 13) + '...'
                  : product == '-'
                    ? '-'
                    : product : null}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                key={obj.downPaymentAmount}
              >
                {leadDeposit}&nbsp;
              </RACTableCell>
              <RACTableCell key={type} className={classes.tdstyle}>
                {type}
              </RACTableCell>
              {customerVerification == 1 ? (
                <RACTableCell className={`${classes.tdstyle}`} key={index}>
                  {customerType && colorId
                    ? customerTypeTag(customerType, colorId)
                    : "-"}
                </RACTableCell>
              ) : null}
              <RACTableCell key={obj.leadSummary} className={classes.tdstyle}>
                {obj.promoCode != null ? obj.promoCode : notesColumn}
              </RACTableCell>
            </RACTableRow>
          );
        }
      })}
    </>
  );
  /**SALESLEAD GRID DATA BINDING */



  const buildreimbursedData = () => (
    <>
      {mappedData.map((obj: any, index: any) => {
        /* Get the Web Lead Details for the given matching Order ID */
        let webLeadInfo: any = {};
        if (obj.webleadPaymentId != null) {
          webLeadInfo = webLeadData.filter(
            (val) =>  val.orderNumber === obj.webleadPaymentId || val.entWebLeadPaymentId && (val.entWebLeadPaymentId.toString() === obj.webleadPaymentId)
          );
        }
        if (
          Object.keys(webLeadInfo).length > 0 &&
          (webLeadInfo[0].status === 'PA' ||
            webLeadInfo[0].status === 'PR' ||
            webLeadInfo[0].status === 'REFUND')
        ) {
          const inventoryNumber = obj.inventories.length <= 0 || obj.inventories === undefined ? obj.inventoryNumber : obj.inventories[0].inventoryNumber;
          const modelNumber = obj.inventories.length <= 0 || obj.inventories === undefined ? obj.modelNumber : obj.inventories[0].modelNumber
          const c = new Date(obj.createdDate);
          const time1 = c.toISOString().split('T')[0];

          const dateFormat = moment(time1).format('MM/DD/YYYY');
          const num =
            obj.mobilePhoneNumber !== null &&
              obj.mobilePhoneNumber !== undefined
              ? obj.mobilePhoneNumber
              : '-';
          const phoneRegex1 =
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

          const formatmobileNum =
            obj.mobilePhoneNumber !== null &&
              obj.mobilePhoneNumber !== undefined
              ?
              num.replace(phoneRegex1, '($1) $2-$3')
              : '-';
          let reservedCondition: any;
          const notesColumn = obj.leadSummary === null ? '-' : obj.leadSummary;

          /***converted condition */
          const Reimbursed = 'Reimbursed';
          const wlAmount =
            webLeadInfo[0].paymentInfo.balanceAmount !== 0
              ? '$'.concat(webLeadInfo[0].paymentInfo.balanceAmount)
              : '-';
          const typeReserved = 'Reserved';

          const typeCustomerOrder = 'Customer Order';
          const type =
            (obj.webleadPaymentId !== null &&
              inventoryNumber !== null) ||
              (obj.webleadPaymentId !== null &&
                obj.promoCode !== null &&
                inventoryNumber !== null)
              ? typeReserved
              : (modelNumber !== '' && modelNumber !== null) &&
                inventoryNumber === null
                ?
                typeCustomerOrder
                : '-';
          if (type == typeReserved) {
            reservedCondition = 6;
          } else {
            reservedCondition = 2;
          }
          const product =
            type == typeReserved
              ? inventoryNumber
              : type == typeCustomerOrder
                ? modelNumber
                : type == '-' && (modelNumber !== '' && modelNumber !== null)
                  ? modelNumber
                  : '-';
        /* The above code is using destructuring assignment to extract the `customerType` and `colorId`
        properties from the object returned by the `getCustomerType` function. This allows you to
        access these properties directly without having to use the object's full name. */
        const { customerType, colorId } = getCustomerType(obj);


          return obj.leadStatus === 'Converted' ? (
            <RACTableRow
              className={`${classes.tablerow1}  ${classes.tablebggreen}`}
              title="Converted"
              data-testid={index + obj.firstName}
              key={index}
              hover
              backgroundColor={'white'}
            >
              <RACTableCell
                key={obj.downPaymentAmount}
                className={classes.tdstyle}
              >
                <Typography
                  variant="caption"
                  className={`${classes.racpaddashboardlinks} ${classes.racpadbrgreen}`}
                ></Typography>
                {obj.lastName}
              </RACTableCell>
              <RACTableCell
                key={obj.downPaymentAmount}
                className={classes.tdstyle}
              >
                <Typography
                  variant="caption"
                  className={`${classes.racpaddashboardlinks}`}
                ></Typography>
                {obj.firstName}
              </RACTableCell>
              <RACTableCell className={classes.tdstyle} key={dateFormat}>
                {dateFormat}
              </RACTableCell>
              <RACTableCell className={classes.tdstyle} key={formatmobileNum}>
                {formatmobileNum}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                title={product !== null && product !== undefined ? product.length > 13 ? product : null : null}
                key={inventoryNumber}
              >
                {product !== null && product !== undefined ? product.length > 13
                  ? product.substring(0, 13) + '...'
                  : product == '-'
                    ? '-'
                    : product : null}
              </RACTableCell>
              <RACTableCell className={classes.tdstyle} key={wlAmount}>
                {wlAmount}&nbsp;
              </RACTableCell>
              <RACTableCell className={classes.tdstyle} key={type}>
                {Reimbursed}
              </RACTableCell>
              {customerVerification == 1 ? (
                <RACTableCell className={`${classes.tdstyle}`} key={index}>
                  {customerType && colorId
                    ? customerTypeTag(customerType, colorId)
                    : "-"}
                </RACTableCell>
              ) : null}
              <RACTableCell className={classes.tdstyle} key={obj.leadSummary}>
                {obj.promoCode != null ? obj.promoCode : notesColumn}
              </RACTableCell>
            </RACTableRow>
          ) : (
            <RACTableRow
              className={` ${classes.tablerow1} ${classes.commonRow}`}

              onClick={() =>
                Redirect(
                  index,
                  modelNumber,
                  obj.storeNumber,
                  reservedCondition,
                  inventoryNumber
                )
              }
              data-testid={index + obj.firstName}
              key={index}
              hover
              backgroundColor={'white'}
            >
              <RACTableCell
                key={obj.downPaymentAmount}
                className={classes.tdstyle}
              >
                <Typography
                  variant="caption"
                  className={` ${classes.tablerow1} ${classes.commonRow}`}
                ></Typography>
                {obj.lastName}
              </RACTableCell>
              <RACTableCell
                key={obj.downPaymentAmount}
                className={classes.tdstyle}
              >
                <Typography
                  variant="caption"
                  className={` ${classes.tablerow1} ${classes.commonRow}`}
                ></Typography>
                {obj.firstName}
              </RACTableCell>
              <RACTableCell className={classes.tdstyle} key={dateFormat}>
                {dateFormat}
              </RACTableCell>
              <RACTableCell className={classes.tdstyle} key={formatmobileNum}>
                {formatmobileNum}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                title={product !== null && product !== undefined ? product.length > 13 ? product : null : null}
                key={inventoryNumber}
              >
                {product !== null && product !== undefined ? product.length > 13
                  ? product.substring(0, 13) + '...'
                  : product == '-'
                    ? '-'
                    : product : null}
              </RACTableCell>
              <RACTableCell className={classes.tdstyle} key={wlAmount}>
                {wlAmount}&nbsp;
              </RACTableCell>
              <RACTableCell className={classes.tdstyle} key={type}>
                {Reimbursed}
              </RACTableCell>
              {customerVerification == 1 ? (
                <RACTableCell className={`${classes.tdstyle}`} key={index}>
                  {customerType && colorId
                    ? customerTypeTag(customerType, colorId)
                    : "-"}
                </RACTableCell>
              ) : null}
              <RACTableCell className={classes.tdstyle} key={obj.leadSummary}>
                {obj.promoCode != null ? obj.promoCode : notesColumn}
              </RACTableCell>
            </RACTableRow>
          );
        }
      })}
    </>
  );



  const buildSalesGrid = () => (
    <>
      {mappedData.map((obj: any, index: any) => {
        debugger
        /* Get the Web Lead Details for the given matching Order ID */
        let webLeadInfo: any = {};

        if (obj.webleadPaymentId != null) {
          webLeadInfo = webLeadData.filter(
            (val) => val.orderNumber === obj.webleadPaymentId || val.entWebLeadPaymentId && (val.entWebLeadPaymentId.toString() === obj.webleadPaymentId)
          );
        }
        const inventoryNumber = obj.inventories.length <= 0 || obj.inventories === undefined ? obj.inventoryNumber : obj.inventories[0].inventoryNumber;
        const modelNumber = obj.inventories.length <= 0 || obj.inventories === undefined ? obj.modelNumber : obj.inventories[0].modelNumber
        const typeReserved = 'Reserved';
        const typeCustomerOrder = 'Customer Order';
        const c = new Date(obj.createdDate);
        const time1 = c.toISOString().split('T')[0];
        const dateFormat = moment(time1).format('MM/DD/YYYY');
        const num =
          obj.mobilePhoneNumber !== null && obj.mobilePhoneNumber !== undefined
            ? obj.mobilePhoneNumber
            : '-';
        const phoneRegex1 =
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

        const formatmobileNum =
          obj.mobilePhoneNumber !== null && obj.mobilePhoneNumber !== undefined
            ?
            num.replace(phoneRegex1, '($1) $2-$3')
            : '-';
        let reservedCondition: any;

        const notesColumn = obj.leadSummary === null ? '-' : obj.leadSummary;

        /* The above code is using destructuring assignment to extract the `customerType` and `colorId`
        properties from the object returned by the `getCustomerType` function. This allows you to
        access these properties directly without having to use the object's full name. */
        const { customerType, colorId } = getCustomerType(obj);
        console.log('Object Values', getCustomerType(obj), obj);

        /*** CONVERTED CONDITION ***/
        if (
          obj.leadStatus !== 'Converted' &&
          (Object.keys(webLeadInfo).length == 0 ||
            (Object.keys(webLeadInfo).length != 0 &&
              webLeadInfo[0].status != 'PA' &&
              webLeadInfo[0].status != 'PR' &&
              webLeadInfo[0].status != 'REFUND'))
        ) {
          const type =
            (obj.webleadPaymentId !== null &&
              inventoryNumber !== null) ||
              (obj.webleadPaymentId !== null &&
                obj.promoCode !== null &&
                inventoryNumber !== null)
              ? typeReserved
              :(modelNumber !== '' && modelNumber !== null) &&
              inventoryNumber == null
                ?
                typeCustomerOrder
                : '-';
          reservedCondition = type == 'Reserved' ? 6 : 2;
          const wlAmount =
          obj.webleadPaymentId != null ? obj.downPaymentAmount !== 0 && obj.downPaymentAmount !== null
              ? '$'.concat(obj.downPaymentAmount)
              : '-' : '-';
          const product =
            type == typeReserved
              ? inventoryNumber
              : type == typeCustomerOrder
                ? modelNumber
                : type == '-' && (modelNumber !== '' && modelNumber !== null)
                  ? modelNumber
                  : '-';
          const condition = obj.webleadPaymentId === null;

          return (
            <RACTableRow
              className={
                condition
                  ? `${classes.tablerow1} ${classes.commonRow}`
                  : `${classes.tablebgblues}`
              }
              onClick={() =>
                Redirect(
                  index,
                  modelNumber,
                  obj.storeNumber,
                  reservedCondition,
                  inventoryNumber
                )
              }
              data-testid={index + obj.firstName}
              key={index}
              hover
              backgroundColor={"white"}
            >
              {obj.webleadPaymentId === null ? (
                <RACTableCell
                  key={obj.downPaymentAmount}
                  className={classes.tdstyle}
                >
                  <Typography
                    variant="caption"
                    className={classes.tablerow1}
                  ></Typography>
                  {obj.lastName}
                </RACTableCell>
              ) : (
                <RACTableCell
                  key={obj.downPaymentAmount}
                  className={classes.tdstyle}
                >
                  <Typography
                    variant="caption"
                    className={`${classes.racpaddashboardlinks} ${classes.racpadbrleftmsgs}`}
                  ></Typography>
                  {obj.lastName}
                </RACTableCell>
              )}
              {obj.webleadPaymentId === null ? (
                <RACTableCell
                  key={obj.downPaymentAmount}
                  className={classes.tdstyle}
                >
                  <Typography
                    variant="caption"
                    className={classes.tablerow1}
                  ></Typography>
                  {obj.firstName}
                </RACTableCell>
              ) : (
                <RACTableCell
                  key={obj.downPaymentAmount}
                  className={classes.tdstyle}
                >
                  <Typography
                    variant="caption"
                    className={`${classes.racpaddashboardlinks}`}
                  ></Typography>
                  {obj.firstName}
                </RACTableCell>
              )}
              <RACTableCell className={classes.tdstyle} key={dateFormat}>
                {dateFormat}
              </RACTableCell>
              <RACTableCell className={classes.tdstyle} key={formatmobileNum}>
                {formatmobileNum}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                title={
                  product !== null && product !== undefined
                    ? product.length > 13
                      ? product
                      : null
                    : null
                }
                key={inventoryNumber}
              >
                {product !== null && product !== undefined
                  ? product.length > 13
                    ? product.substring(0, 13) + "..."
                    : product == "-"
                    ? "-"
                    : product
                  : null}
              </RACTableCell>
              <RACTableCell className={classes.tdstyle} key={wlAmount}>
                {wlAmount}&nbsp;
              </RACTableCell>
              <RACTableCell className={classes.tdstyle} key={type}>
                {type}
              </RACTableCell>
              {customerVerification == 1 ? (
                <RACTableCell className={`${classes.tdstyle}`} key={index}>
                  {customerType && colorId
                    ? customerTypeTag(customerType, colorId)
                    : "-"}
                </RACTableCell>
              ) : null}
              <RACTableCell className={classes.tdstyle} key={obj.leadSummary}>
                {obj.promoCode != null ? obj.promoCode : notesColumn}
              </RACTableCell>
            </RACTableRow>
          );
        }
      })}
      {buildreimbursedData()}
      {buildConvertedData()}
    </>
    //reimbursed
  );

  //converted expand data

  const buildConvertedExpandData = () => (
    <>
      {mappedData.map((obj: any, index: any) => {
        let webLeadInfo: any = {};

        if (obj.webleadPaymentId !== null) {
          webLeadInfo = webLeadData.filter(
            (val) =>  val.orderNumber === obj.webleadPaymentId || val.entWebLeadPaymentId && (val.entWebLeadPaymentId.toString() === obj.webleadPaymentId)
          );
        }
        const inventoryNumber = obj.inventories.length <= 0 || obj.inventories === undefined ? obj.inventoryNumber : obj.inventories[0].inventoryNumber;
        const modelNumber = obj.inventories.length <= 0 || obj.inventories === undefined ? obj.modelNumber : obj.inventories[0].modelNumber
        const typeReserved = 'Reserved';
        const typeCustomerOrder = 'Customer Order';
        const typeReimbursed = 'Reimbursed';
        const c = new Date(obj.createdDate);
        const time1 = c.toISOString().split('T')[0];
        const dateFormat = moment(time1).format('MM/DD/YYYY');

        const formatNum =
          obj.mobilePhoneNumber !== null && obj.mobilePhoneNumber !== undefined
            ? obj.mobilePhoneNumber
            : '-';
        const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

        const formatmobileNum =
          obj.mobilePhoneNumber !== null && obj.mobilePhoneNumber !== undefined
            ? formatNum.replace(phoneRegex, '($1) $2-$3')
            : '-';
        const description =
          obj.productDescription !== null ? obj.productDescription : '-';
        const now = +new Date();

        const leadConvertedDate =
          obj.leadConvertedDate !== null && obj.leadConvertedDate !== undefined
            ? obj.leadConvertedDate
            : '-';
        const createdAt = +new Date(Date.parse(leadConvertedDate.toString()));
        const oneDay = 24 * 60 * 60 * 1000;

        const notesColumn = obj.leadSummary === null ? '-' : obj.leadSummary;
        const type =
          Object.keys(webLeadInfo).length > 0 &&
            (webLeadInfo[0].status == 'PA' ||
              webLeadInfo[0].status == 'PR' ||
              webLeadInfo[0].status == 'REFUND')
            ? typeReimbursed
            : obj.webleadPaymentId !== null &&
              inventoryNumber !== null
              ? typeReserved
              :
              (modelNumber !== '' && modelNumber !== null) &&
                inventoryNumber == null
                ? typeCustomerOrder
                : '-';

         /* The above code is using destructuring assignment to extract the `customerType` and `colorId`
        properties from the object returned by the `getCustomerType` function. This allows you to
        access these properties directly without having to use the object's full name. */
        const { customerType, colorId } = getCustomerType(obj);

        const product =
          type == typeReserved
            ? inventoryNumber
            : type == typeCustomerOrder
              ? modelNumber
              : type == '-' && (modelNumber !== '' && modelNumber !== null)
                ? modelNumber
                : '-';
        const wlAmount =
          obj.webleadPaymentId != null ? obj.downPaymentAmount !== null && obj.downPaymentAmount !== 0
            ? '$'.concat(obj.downPaymentAmount)
            : '-' : '-';

        if (
          now - createdAt < oneDay &&
          obj.leadStatus == 'Converted' &&
          type !== typeReimbursed
        ) {

          const leadDeposit =
            obj.webleadPaymentId !== null
              ? webLeadInfo[0].paymentInfo.balanceAmount !== 0
                ? '$'.concat(webLeadInfo[0].paymentInfo.balanceAmount)
                : '-'
              : obj.webleadPaymentId != null ? obj.downPaymentAmount !== 0
                ? '$'.concat(obj.downPaymentAmount)
                : '-' : '-';
          return (
            <RACTableRow
              className={`${classes.tablerow1} ${classes.tablebggreen}`}
              title="Converted"
              key={index}
              hover
              backgroundColor={'white'}
            >
              <RACTableCell key={obj.firstName} className={classes.tdstyle}>
                {' '}
                <Typography
                  variant="caption"
                  className={`${classes.racpaddashboardlinks} ${classes.racpadbrgreen}`}
                ></Typography>
                {obj.lastName}
              </RACTableCell>
              <RACTableCell key={obj.firstName} className={classes.tdstyle}>
                {' '}
                <Typography
                  variant="caption"
                  className={`${classes.racpaddashboardlinks}`}
                ></Typography>
                {obj.firstName}
              </RACTableCell>
              <RACTableCell className={classes.tdstyle} key={obj.date}>
                {dateFormat}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                key={obj.mobilePhoneNumber}
              >
                {formatmobileNum}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                title={product !== null && product !== undefined ? product.length > 13 ? product : null : null}
                key={inventoryNumber}
              >
                {product !== null && product !== undefined ? product.length > 13
                  ? product.substring(0, 13) + '...'
                  : product == '-'
                    ? '-'
                    : product : null}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                title={description.length > 32 ? description : null}
                key={description}
              >
                {description !== null
                  ? description.length > 35
                    ? description.substring(0, 35 - 4) + '...'
                    : description
                  : description}
              </RACTableCell>

              <RACTableCell className={classes.tdstyle} key={wlAmount}>
                {leadDeposit}&nbsp;
              </RACTableCell>
              <RACTableCell className={classes.tdstyle} key={type}>
                {type}
              </RACTableCell>
              <RACTableCell key={obj.webleadPaymentId}>
                {obj.webleadPaymentId}
              </RACTableCell>
              {customerVerification == 1 ? (
                <RACTableCell className={`${classes.tdstyle}`} key={index}>
                  {customerType && colorId
                    ? customerTypeTag(customerType, colorId)
                    : "-"}
                </RACTableCell>
              ) : null}
              <RACTableCell className={classes.tdstyle} key={obj.leadSummary}>
                {obj.promoCode != null ? obj.promoCode : notesColumn}
              </RACTableCell>
            </RACTableRow>
          );
        }
      })}
    </>
  );

  //reimbursed POpup code


  const buildreimbursedDataExpandPopoup = () => (
    <>
      {mappedData.map((obj: any, index: any) => {
        /* Get the Web Lead Details for the given matching Order ID */
        let webLeadInfo: any = {};
        if (obj.webleadPaymentId != null) {
          webLeadInfo = webLeadData.filter(
            (val) =>  val.orderNumber === obj.webleadPaymentId || val.entWebLeadPaymentId && (val.entWebLeadPaymentId.toString() === obj.webleadPaymentId)
          );
        }
        if (
          Object.keys(webLeadInfo).length > 0 &&
          (webLeadInfo[0].status == 'PA' ||
            webLeadInfo[0].status == 'PR' ||
            webLeadInfo[0].status == 'REFUND')
        ) {
          /* Get the Web Lead Details for the given matching Order ID */
          const inventoryNumber = obj.inventories.length <= 0 || obj.inventories === undefined ? obj.inventoryNumber : obj.inventories[0].inventoryNumber;
          const modelNumber = obj.inventories.length <= 0 || obj.inventories === undefined ? obj.modelNumber : obj.inventories[0].modelNumber
          const c = new Date(obj.createdDate);
          const time1 = c.toISOString().split('T')[0];
          const dateFormat = moment(time1).format('MM/DD/YYYY');

       /* The above code is using destructuring assignment to extract the `customerType` and `colorId`
        properties from the object returned by the `getCustomerType` function. This allows you to
        access these properties directly without having to use the object's full name. */
        const { customerType, colorId } = getCustomerType(obj);

          let reservedCondition: any;

          const formatNum =
            obj.mobilePhoneNumber !== null &&
              obj.mobilePhoneNumber !== undefined
              ? obj.mobilePhoneNumber
              : '-';
          const phoneRegex =
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

          const formatmobileNum =
            obj.mobilePhoneNumber !== null &&
              obj.mobilePhoneNumber !== undefined
              ? formatNum.replace(phoneRegex, '($1) $2-$3')
              : '-';
          const description =
            obj.productDescription !== null ? obj.productDescription : '-';

          const notesColumn = obj.leadSummary === null ? '-' : obj.leadSummary;
          // const product = inventoryNumber !== null ? inventoryNumber : inventoryNumber == null && modelNumber !== '' ? "-" : modelNumber
          const wlAmount =
            webLeadInfo[0].paymentInfo.balanceAmount !== 0
              ? '$'.concat(webLeadInfo[0].paymentInfo.balanceAmount)
              : '-';
          const typeReserved = 'Reserved';
          const typeCustomerOrder = 'Customer Order';
          const type =
            (obj.webleadPaymentId !== null &&
              inventoryNumber !== null) ||
              (obj.webleadPaymentId !== null &&
                obj.promoCode !== null &&
                inventoryNumber !== null)
              ? typeReserved
              : (modelNumber !== '' && modelNumber !== null) &&
                inventoryNumber == null
                ?
                typeCustomerOrder
                : '-';
          if (type == 'Reserved') {
            reservedCondition = 6;
          } else {
            reservedCondition = 2;
          }
          const product =
            type == typeReserved
              ? inventoryNumber
              : type == typeCustomerOrder
                ? modelNumber
                : type == '-' && (modelNumber !== '' && modelNumber !== null)
                  ? modelNumber
                  : '-';
          const Reimbursed = 'Reimbursed';

          return obj.leadStatus === 'Converted' ? (
            <RACTableRow
              className={`${classes.tablerow1}  ${classes.tablebggreen}`}
              title="Converted"


              data-testid="redirectTest"
              key={index}
              hover
              backgroundColor={'white'}
            >
              <RACTableCell
                key={obj.downPaymentAmount}
                className={classes.tdstyle}
              >
                <Typography
                  variant="caption"
                  className={`${classes.racpaddashboardlinks} ${classes.racpadbrgreen}`}
                ></Typography>
                {obj.lastName}
              </RACTableCell>
              <RACTableCell
                key={obj.downPaymentAmount}
                className={classes.tdstyle}
              >
                <Typography
                  variant="caption"
                  className={`${classes.racpaddashboardlinks}`}
                ></Typography>
                {obj.firstName}
              </RACTableCell>
              <RACTableCell className={classes.tdstyle} key={obj.date}>
                {dateFormat}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                key={obj.mobilePhoneNumber}
              >
                {formatmobileNum}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                title={product !== null && product !== undefined ? product.length > 13 ? product : null : null}
              >
                {product !== null && product !== undefined ? product.length > 13
                  ? product.substring(0, 13) + '...'
                  : product == '-'
                    ? '-'
                    : product : null}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                title={description.length > 32 ? description : null}
                key={description}
              >
                {description != null
                  ? description.length > 35
                    ? description.substring(0, 35 - 4) + '...'
                    : description
                  : description}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                key={obj.downPaymentAmount}
              >
                {wlAmount}&nbsp;
              </RACTableCell>
              <RACTableCell className={classes.tdstyle} key={type}>
                {Reimbursed}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                key={obj.webleadPaymentId}
              >
                {obj.webleadPaymentId == null ? '-' : obj.webleadPaymentId}
              </RACTableCell>
              {customerVerification == 1 ? (
                <RACTableCell className={`${classes.tdstyle}`} key={index}>
                  {customerType && colorId
                    ? customerTypeTag(customerType, colorId)
                    : "-"}
                </RACTableCell>
              ) : null}
              <RACTableCell className={classes.tdstyle} key={obj.leadSummary}>
                {obj.promoCode != null ? obj.promoCode : notesColumn}
              </RACTableCell>
            </RACTableRow>
          ) : (
            <RACTableRow
              className={`${classes.tablerow1} ${classes.commonRow}`}

              onClick={() =>
                Redirect(
                  index,
                  modelNumber,

                  obj.storeNumber,
                  reservedCondition,
                  inventoryNumber
                )
              }
              data-testid="redirectTest"
              key={index}
              hover
              backgroundColor={'white'}
            >
              <RACTableCell
                key={obj.downPaymentAmount}
                className={classes.tdstyle}
              >
                <Typography
                  variant="caption"
                  className={` ${classes.tablerow1} ${classes.commonRow}`}
                ></Typography>
                {obj.lastName}
              </RACTableCell>
              <RACTableCell
                key={obj.downPaymentAmount}
                className={classes.tdstyle}
              >
                <Typography
                  variant="caption"
                  className={` ${classes.tablerow1} ${classes.commonRow}`}
                ></Typography>
                {obj.firstName}
              </RACTableCell>
              <RACTableCell className={classes.tdstyle} key={obj.date}>
                {dateFormat}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                key={obj.mobilePhoneNumber}
              >
                {formatmobileNum}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                title={product !== null && product !== undefined ? product.length > 13 ? product : null : null}
              >
                {product !== null && product !== undefined ? product.length > 13
                  ? product.substring(0, 13) + '...'
                  : product == '-'
                    ? '-'
                    : product : null}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                title={description.length > 32 ? description : null}
                key={description}
              >
                {description != null
                  ? description.length > 35
                    ? description.substring(0, 35 - 4) + '...'
                    : description
                  : description}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                key={obj.downPaymentAmount}
              >
                {wlAmount}&nbsp;
              </RACTableCell>
              <RACTableCell className={classes.tdstyle} key={type}>
                {Reimbursed}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                key={obj.webleadPaymentId}
              >
                {obj.webleadPaymentId == null ? '-' : obj.webleadPaymentId}
              </RACTableCell>
              <RACTableCell className={classes.tdstyle} key={obj.leadSummary}>
                {obj.promoCode != null ? obj.promoCode : notesColumn}
              </RACTableCell>
            </RACTableRow>
          );
        }
      })}
    </>
  );
  //expandsaleslead function triggered when expand icon is clicked and shows all datas
  const expandSaleslead = () => {
    expandSale === false ? setExpandSale(true) : setExpandSale(false);
  };

  /************************EXPAND SALES POP UP - STARTS HERE*******************************/

  const expandSalesPopup = () => (
    <>
      {mappedData.map((obj: any, index: any) => {
        let webLeadInfo: any = {};

        if (obj.webleadPaymentId !== null) {
          webLeadInfo = webLeadData.filter(
            (val) =>  val.orderNumber === obj.webleadPaymentId || val.entWebLeadPaymentId && (val.entWebLeadPaymentId.toString() === obj.webleadPaymentId)
          );
        }
        const inventoryNumber = obj.inventories.length <= 0 || obj.inventories === undefined ? obj.inventoryNumber : obj.inventories[0].inventoryNumber;
        const modelNumber = obj.inventories.length <= 0 || obj.inventories === undefined ? obj.modelNumber : obj.inventories[0].modelNumber
        const typeReserved = 'Reserved';
        const typeCustomerOrder = 'Customer Order';
        const c = new Date(obj.createdDate);
        const time1 = c.toISOString().split('T')[0];
        const dateFormat = moment(time1).format('MM/DD/YYYY');

        let reservedCondition: any;

        const formatNum =
          obj.mobilePhoneNumber !== null && obj.mobilePhoneNumber !== undefined
            ? obj.mobilePhoneNumber
            : '-';
        const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

        const formatmobileNum =
          obj.mobilePhoneNumber !== null && obj.mobilePhoneNumber !== undefined
            ? formatNum.replace(phoneRegex, '($1) $2-$3')
            : '-';
        const description =
          obj.productDescription !== null ? obj.productDescription : '-';

        const notesColumn = obj.leadSummary === null ? '-' : obj.leadSummary;

        if (
          obj.leadStatus !== 'Converted' &&
          (Object.keys(webLeadInfo).length == 0 ||
            (Object.keys(webLeadInfo).length !== 0 &&
              webLeadInfo[0].status !== 'PA' &&
              webLeadInfo[0].status !== 'PR' &&
              webLeadInfo[0].status !== 'REFUND'))
        ) {
          const type =
            (obj.webleadPaymentId !== null &&
              inventoryNumber !== null) ||
              (obj.webleadPaymentId !== null &&
                obj.promoCode !== null &&
                inventoryNumber !== null)
              ? typeReserved
              : (modelNumber !== '' && modelNumber !== null) &&
                inventoryNumber == null
                ? typeCustomerOrder
                : '-';
          reservedCondition = type == 'Reserved' ? 6 : 2;
          const product =
            type == typeReserved
              ? inventoryNumber
              : type == typeCustomerOrder
                ? modelNumber
                : type == '-' && (modelNumber !== '' && modelNumber !== null)
                  ? modelNumber
                  : '-';
          const wlAmount =
            obj.webleadPaymentId != null ? obj.downPaymentAmount !== 0 && obj.downPaymentAmount !== null
              ? '$'.concat(obj.downPaymentAmount)
              : '-' : '-';
        /* The above code is using destructuring assignment to extract the `customerType` and `colorId`
        properties from the object returned by the `getCustomerType` function. This allows you to
        access these properties directly without having to use the object's full name. */
        const { customerType, colorId } = getCustomerType(obj);

          const condition =
            obj.webleadPaymentId === null;
          return (
            <RACTableRow
              className={
                condition
                  ? `${classes.tablerow1} ${classes.commonRow}`
                  : classes.tablebgblues
              }

              onClick={() =>
                Redirect(
                  index,
                  modelNumber,

                  obj.storeNumber,
                  reservedCondition,
                  inventoryNumber
                )
              }
              data-testid="redirectTest"
              key={index}
              hover
              backgroundColor={'white'}
            >
              {obj.webleadPaymentId == null ? (
                <RACTableCell
                  key={obj.downPaymentAmount}
                  className={classes.tdstyle}
                >
                  <Typography
                    variant="caption"
                    className={classes.tablerow1}
                  ></Typography>
                  {obj.lastName}
                </RACTableCell>
              ) : (
                <RACTableCell
                  key={obj.downPaymentAmount}
                  className={classes.tdstyle}
                >
                  <Typography
                    variant="caption"
                    className={`${classes.racpaddashboardlinks} ${classes.racpadbrleftmsgs}`}
                  ></Typography>
                  {obj.lastName}
                </RACTableCell>
              )}
              {obj.webleadPaymentId == null ? (
                <RACTableCell
                  key={obj.downPaymentAmount}
                  className={classes.tdstyle}
                >
                  <Typography
                    variant="caption"
                    className={classes.tablerow1}
                  ></Typography>
                  {obj.firstName}
                </RACTableCell>
              ) : (
                <RACTableCell
                  key={obj.downPaymentAmount}
                  className={classes.tdstyle}
                >
                  <Typography
                    variant="caption"
                    className={`${classes.racpaddashboardlinks}`}
                  ></Typography>
                  {obj.firstName}
                </RACTableCell>
              )}
              <RACTableCell className={classes.tdstyle} key={obj.date}>
                {dateFormat}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                key={obj.mobilePhoneNumber}
              >
                {formatmobileNum}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                title={product !== null && product !== undefined ? product.length > 13 ? product : null : null}
              >
                {product !== null && product !== undefined ? product.length > 13
                  ? product.substring(0, 13) + '...'
                  : product == '-'
                    ? '-'
                    : product : null}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                title={description.length > 32 ? description : null}
                key={description}
              >
                {description != null
                  ? description.length > 35
                    ? description.substring(0, 35 - 4) + '...'
                    : description
                  : description}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                key={obj.downPaymentAmount}
              >
                {wlAmount}&nbsp;
              </RACTableCell>
              <RACTableCell className={classes.tdstyle} key={type}>
                {type}
              </RACTableCell>
              <RACTableCell
                className={classes.tdstyle}
                key={obj.webleadPaymentId}
              >
                {obj.webleadPaymentId == null ? '-' : obj.webleadPaymentId}
              </RACTableCell>
              {customerVerification == 1 ? (
                <RACTableCell className={`${classes.tdstyle}`} key={index}>
                  {customerType && colorId
                    ? customerTypeTag(customerType, colorId)
                    : "-"}
                </RACTableCell>
              ) : null}
              <RACTableCell className={classes.tdstyle} key={obj.leadSummary}>
                {obj.promoCode != null ? obj.promoCode : notesColumn}
              </RACTableCell>
            </RACTableRow>
          );
        }
      })}

      {buildreimbursedDataExpandPopoup()}
      {buildConvertedExpandData()}
    </>
  );

  /****************************EXPAND SALES POP UP - ENDS HERE *******************/



  const Redirect = async (
    _index: any,
    modelName: any,
    storeNumber: any,
    reservedCondition: any,
    inventoryNum: any

  ) => {
    let isNavigate = false;
    setExpandSale(false);
    setloader(true);

    const obj = mappedData[_index];
    const inventoryNumber = obj.inventories.length <= 0 || obj.inventories === undefined ? obj.inventoryNumber : obj.inventories[0].inventoryNumber;
    const modelNumber = obj.inventories.length <= 0 || obj.inventories === undefined ? obj.modelNumber : obj.inventories[0].modelNumber;
    const reservedStoreNumber = obj.inventories.length <= 0 || obj.inventories === undefined ? obj.reservedStoreNumber : obj.inventories[0].reservedStoreNumber;
    const setStoreNumInventory =
      reservedStoreNumber == null ||
        reservedStoreNumber == obj.storeNumber
        ? obj.storeNumber
        : reservedStoreNumber;
    const itemNo = inventoryNum !== null ? [inventoryNum] : [];
    const setinventoryStoreNumberMandatory =
      reservedStoreNumber == null
        ? storeNumber
        : reservedStoreNumber;

    const paramsSalesInventory = {
      storeNumbers: [storeNumber],
      itemNumber: itemNo,
      serialNumber: '',
      brand: '',
      bracket: '',
      modelNumber: modelName,
      poNumber: null,
      statusId: null,
      subStatusId: null,
      conditionId: null,
      department: '',
      subDepartment: '',
      setNumber: 0,
      stateId: null,
      addressLine1: '',
      city: '',
      zip: '',
      phoneNumber: '',
    };



    // const inventoryData: any = await getSalesLeadInvenventoryLambda(
    //   paramsSalesInventory
    // );

    const [inventoryData, getCustomerDataSales] = await Promise.all([
      getSalesLeadInvenventoryLambda(paramsSalesInventory),
      obj.globalCustomerId !== null ? getCustomerForsalesLead(obj.globalCustomerId) : null
    ]);


    var responseCustomerSL: any = null;

    if (obj.globalCustomerId !== null) {
      // const getCustomerDataSales: any = await getCustomerForsalesLead(obj.globalCustomerId);
      if (getCustomerDataSales.status === 200 && getCustomerDataSales?.data?.customerId !== undefined) {
        responseCustomerSL = getCustomerDataSales.data.customerId;
        setCustomerId(responseCustomerSL);
        setCustomerDetails({ ...customerDetails, customerId: getCustomerDataSales.data.customerId, saleLeadId: obj.leadId, globalcustomerId: obj.globalCustomerId, webleadPaymentId: obj.webleadPaymentId })
      } else {
        isNavigate = true;
        console.log('log for weblead issue1', obj);
        history.push({
          pathname: `/customer/createcustomer`,
          search: `?salesLeadId=${obj.leadId}&gcid=${obj.globalCustomerId}`,
          state: {
            wleadPaymentId: obj.webleadPaymentId,
          },
        })
      }
      const salesleadId = obj.salesLeadId;
      setCustomerDetails({ ...customerDetails, customerId: responseCustomerSL !== null ? responseCustomerSL : '', saleLeadId: obj.leadId, globalcustomerId: obj.globalCustomerId, webleadPaymentId: obj.webleadPaymentId })


      console.log('responseCustomerSL: ', responseCustomerSL);

      console.log('salesleadId: ', salesleadId);
      const paramsUpdateSalesLead: any = {
        customerId: responseCustomerSL,
        salesLeadId: salesleadId,
      };
      if (getCustomerDataSales.status === 200 && getCustomerDataSales?.data?.customerId !== undefined) {
        console.log('obj.webleadPaymentId', obj.webleadPaymentId);       
        const matchedObjects = obj.webleadPaymentId ? webLeadData.filter(obj1 => {
          return (obj.webleadPaymentId === obj1.orderNumber) || obj1.entWebLeadPaymentId && (obj.webleadPaymentId === obj1.entWebLeadPaymentId.toString());
      }) : [];
      console.log(matchedObjects, 'matched weblead and saleslead');
          const updatewebleadPayload = {
          webleadPaymentId: obj.webleadPaymentId,
          workNotes: '',
          customerId: responseCustomerSL,
          status: 'ATT'
        }
        paramsUpdateSalesLead. webLeadStatus = matchedObjects.length > 0 ? matchedObjects[0].status : null 
        paramsUpdateSalesLead.updatewebleadPayload = updatewebleadPayload;
        console.log('paramsUpdateSalesLead.updatewebleadPayload', paramsUpdateSalesLead.updatewebleadPayload);

        const UpdateCustomerDataSales: any = await UpdateCustomerForsalesLead(
          paramsUpdateSalesLead,
          responseCustomerSL
        );


        console.log('UpdateCustomerDataSales: ', UpdateCustomerDataSales);
      }

    }

    setloader(false);

    if (inventoryData.data.salesLeadInv !== undefined && inventoryData.data.salesLeadInv.response !== undefined) {
      (salesLeadInfo = inventoryData.data.salesLeadInv.response)
      Object.keys(inventoryData.data.storeDetailsInv).length === 0
        ? (storeDetailsInfo = '')
        : (storeDetailsInfo =
          inventoryData.data.storeDetailsInv.response[0].workPhoneNumber);
    } else {
      (salesLeadInfo = []);
    }

        obj1 = salesLeadInfo;
    const obj2 = storeDetailsInfo;

    console.log('obj1', obj1);

    console.log('Store Details : ', storeDetailsInfo);

    console.log('Inventory Details : ', salesLeadInfo);

    console.log(
      '',
      inventoryData.data.salesLeadInv.length == 0 ? inventoryData : false
    );
    setModelnum(modelNumber);
    setLeadId(obj.leadId);
    setwebleadPaymentId(obj.webleadPaymentId);
    setGcid(obj.globalCustomerId);

    setinventorynum(inventoryNumber);
    const wrkPhNum = storeDetailsInfo;
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const workPhoneNumberFormat =
      wrkPhNum !== null && wrkPhNum !== undefined
        ? wrkPhNum.replace(phoneRegex, '($1) $2-$3')
        : '';

    setworkPhoneNumber(workPhoneNumberFormat);

    setneighbouringStoreNumber(reservedStoreNumber);

    //customer order
    if (inventoryNumber === null) {
      debugger;
      if (obj.approvalStatus === 'Conditional Approval') {

        setAppprovalPopup(true);
      } else if (
        obj.globalCustomerId !== null &&
        responseCustomerSL !== undefined &&
        responseCustomerSL !== null
      ) {
      if (((obj.modelNumber !== "" && obj.modelNumber !== null)|| obj.bundleModelNumber) && obj.inventoryNumber == null){
          isNavigate = true;
          history.push(`/agreement/customerorder/itemsearch/${responseCustomerSL}?salesLeadId=${obj.leadId}`);
        }
        else {
          isNavigate = true;
          history.push(`/agreement/rental/itemsearch/${responseCustomerSL}?salesLeadId=${obj.leadId}`);
        }

        // } else if (
        //   obj.leadSummary === 'DE-AV' &&
        //   obj.globalCustomerId !== null &&
        //   responseCustomerSL !== undefined &&
        //   responseCustomerSL !== null
        // ) {
        //   //info page
        //  history.push `customer/createcustomer/${responseCustomerSL}/customer`;
      } else if (obj.leadSummary !== 'DE-AV' && obj.leadSummary !== 'DE-A') {
        console.log('log for weblead issue2', obj);
        isNavigate = true;
        //validation page
        // history.push(`/customer/createcustomer?salesLeadId=${obj.leadId}&gcid=${obj.globalCustomerId}`);
        history.push({
          pathname: `/customer/createcustomer`,
          search: `?salesLeadId=${obj.leadId}&gcid=${obj.globalCustomerId}`,
          state: {
            wleadPaymentId: obj.webleadPaymentId,
          },
        })
      }
      //data not valid
      else if (
        ((obj.leadSummary === 'DE-A' || obj.leadSummary === 'DE-AV') &&
          (obj.globalCustomerId === null || obj.globalCustomerId !== null) &&
          responseCustomerSL === undefined) ||
        responseCustomerSL == null
        // eslint-disable-next-line sonarjs/no-duplicated-branches
      ) {
        console.log('log for weblead issue3', obj);
        //validation page
        // history.push(`/customer/createcustomer?salesLeadId=${obj.leadId}&gcid=${obj.globalCustomerId}`);
        isNavigate = true;
        history.push({
          pathname: `/customer/createcustomer`,
          search: `?salesLeadId=${obj.leadId}&gcid=${obj.globalCustomerId}`,
          state: {
            wleadPaymentId: obj.webleadPaymentId,
          },
        })
      }
    }
    //3.Transferpopup
    //3.1//reserved with wl unreseve popup (reserved in currentStore but unreserved ...viceversa)\

    else if (reservedStoreNumber !== null) {
      if (reservedStoreNumber !== obj.storeNumber) {
        if (Object.keys(inventoryData.data.salesLeadInv).length === 0) {
          isNavigate = true;
          setUnreserved1(true);

          console.log('condition1', inventoryData.data.salesLeadInv);
        } else if (obj2.statusEn === 'Reserved') {
          isNavigate = true;
          setTransferPopup(true);
        }
      }


      else if (reservedStoreNumber === obj.storeNumber) {
        if (Object.keys(inventoryData.data.salesLeadInv).length === 0) {
          isNavigate = true;
          setUnreserved2(true);
        } else if (
          obj2.statusEn === 'Reserved' &&
          obj.leadSummary === 'DE-A' &&
          responseCustomerSL !== undefined
        ) {
          isNavigate = true;
          history.push(`/agreement/rental/itemsearch/${responseCustomerSL}?salesLeadId=${obj.leadId}`);

        } else if (
          obj2.statusEn === 'Reserved' &&
          obj.leadSummary !== 'DE-AV' &&
          obj.leadSummary !== 'DE-A'
        ) {
          console.log('log for weblead issue4', obj);
          isNavigate = true;
          //validation page
          // history.push(`/customer/createcustomer?salesLeadId=${obj.leadId}&gcid=${obj.globalCustomerId}`);
          history.push({
            pathname: `/customer/createcustomer`,
            search: `?salesLeadId=${obj.leadId}&gcid=${obj.globalCustomerId}`,
            state: {
              wleadPaymentId: obj.webleadPaymentId,
            },
          })
        }
        //data not valid condition
        else if (
          ((obj.leadSummary === 'DE-A' || obj.leadSummary === 'DE-AV') &&
            (obj.globalCustomerId === null || obj.globalCustomerId !== null) &&
            obj2.statusEn === 'Reserved' &&
            responseCustomerSL === undefined) ||
          responseCustomerSL == null
          // eslint-disable-next-line sonarjs/no-duplicated-branches
        ) {
          console.log('log for weblead issue5', obj);
          //validation page
          //history.push ` customer/createcustomer?salesLeadId=${obj.leadId}`
          // history.push(`/customer/createcustomer?salesLeadId=${obj.leadId}&gcid=${obj.globalCustomerId}`);
          isNavigate = true;
          history.push({
            pathname: `/customer/createcustomer`,
            search: `?salesLeadId=${obj.leadId}&gcid=${obj.globalCustomerId}`,
            state: {
              wleadPaymentId: obj.webleadPaymentId,
            },
          })

        }
      }
    }


    //approved customer
    else if (
      ((modelNumber === null &&
        obj.webleadPaymentId == null) ||
        (modelNumber == null &&
          obj.webleadPaymentId != null)) &&
      obj.leadSummary === 'DE-A' &&
      responseCustomerSL !== undefined &&
      responseCustomerSL !== null
    ) {
      isNavigate = true;
      //item search page
      history.push(`/agreement/rental/itemsearch/${responseCustomerSL}?salesLeadId=${obj.leadId}`);
    }
    //status==DOBSSN (except DE-A & DE-AV)
    else if (
      ((modelNumber === null &&
        obj.webleadPaymentId == null) ||
        (modelNumber == null &&
          obj.webleadPaymentId != null)) &&
      obj.leadSummary !== 'DE-A' &&
      obj.leadSummary !== 'DE-AV'
    ) {
      isNavigate = true;
      console.log('log for weblead issue6', obj);
      //validation page
      // history.push(`/customer/createcustomer?salesLeadId=${obj.leadId}&gcid=${obj.globalCustomerId}`);
      history.push({
        pathname: `/customer/createcustomer`,
        search: `?salesLeadId=${obj.leadId}&gcid=${obj.globalCustomerId}`,
        state: {
          wleadPaymentId: obj.webleadPaymentId,
        },
      })
    }

    if (isNavigate == false) {
      debugger;
      if (responseCustomerSL !== null) {
        if (obj.globalCustomerId !== null &&
          responseCustomerSL !== undefined &&
          responseCustomerSL !== null) {

          if (((obj.modelNumber !== "" && obj.modelNumber !== null)|| obj.bundleModelNumber) && obj.inventoryNumber == null) {
            history.push(`/agreement/customerorder/itemsearch/${responseCustomerSL}?salesLeadId=${obj.leadId}`);
          }
          else {
            history.push(`/agreement/rental/itemsearch/${responseCustomerSL}?salesLeadId=${obj.leadId}`);
          }
        }
        else {
          history.push({
            pathname: `/customer/createcustomer`,
            search: `?salesLeadId=${obj.leadId}&gcid=${obj.globalCustomerId}`,
            state: {
              wleadPaymentId: obj.webleadPaymentId,
            },
          })
        }

        if (obj.globalCustomerId !== null &&
          responseCustomerSL !== undefined &&
          responseCustomerSL !== null) {
            debugger;
           if (obj.modelNumber !== "" && obj.modelNumber !== null && obj.inventoryNumber == null) {
            history.push(`/agreement/customerorder/itemsearch/${responseCustomerSL}?salesLeadId=${obj.leadId}`);
          }
          else {
            history.push(`/agreement/rental/itemsearch/${responseCustomerSL}?salesLeadId=${obj.leadId}`);
          }
        }
        else {
          history.push({
            pathname: `/customer/createcustomer`,
            search: `?salesLeadId=${obj.leadId}&gcid=${obj.globalCustomerId}`,
            state: {
              wleadPaymentId: obj.webleadPaymentId,
            },
          })
        }

      } else {
        history.push({
          pathname: `/customer/createcustomer`,
          search: `?salesLeadId=${obj.leadId}&gcid=${obj.globalCustomerId}`,
          state: {
            wleadPaymentId: obj.webleadPaymentId,
          },
        })
      }


    }


  };
  const ExpandSalesPopdiv = () => {
    return (
      <Grid
        container
        spacing={1}
        className={`${classes.fixedheadertableonline1} ${classes.RACcardHeightModal}`}
      >
        <Expandicon
          className={classes.RACexpandIconclose}
          data-bs-toggle="modal"
          data-bs-target="#salesleads"
          onClick={() => expandSaleslead()}
          data-testid="expandSLClose"
        ></Expandicon>
        <RACTable
          className={`${classes1.salesleadPopupTbl}`}
          renderTableHead={buildExpandSalesLeadHeadPopup}
          renderTableContent={expandSalesPopup}
          stickyHeader
        />
      </Grid>
    );
  };
  const tempSalesLead = [
    {
      display: 'Last Name',
    },
    {
      display: 'First Name',
    },
    {
      display: 'Date',
    },
    {
      display: 'Phone Number',
    },
    {
      display: 'Product',
    },

    {
      display: 'WL Deposit',
    },
    {
      display: 'Type',
    },
    ...(customerVerification == 1 ? [{ display: 'Customer Type' }] : []),
    {
      display: 'Notes',
    },
  ];

  const UnreservedPopupChildrenSmall = () => {
    return (
      <Grid>
        <Grid
          className={`${classes.RACcardlg12} ${classes.px2}  ${classes.ms1} ${classes.me1}`}
        >
          <Typography className={`${classes.popupTextStyle} ${classes.mb2}`}>
            Inventory #&nbsp;
            {inventorynum}&nbsp;is no longer available for use.
          </Typography>

          <Grid>
            <Typography className={`${classes.popupTextStyle} ${classes.mb2}`}>
              The same inventory model #&nbsp;
              {modelNumber}&nbsp;will be searched for alternative items to use.
            </Typography>
          </Grid>
        </Grid>

        <Grid className={classes.btnlocation}>
          <RACButton
            autoFocus
            size="small"
            // disabled={hidevoid}
            variant="contained"
            color="primary"
            isRounded={false}
            style={{ textTransform: 'none' }}
            onClick={() => okUnreservedPopup2()}
            data-testid="okUnreserve2"
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const transferRequiredChildren = () => {
    return (
      <Grid>
        <Grid
          className={`${classes.RACcardlg12} ${classes.px2}  ${classes.ms1} ${classes.me1}`}
        >
          <Typography className={`${classes.popupTextStyle} ${classes.mb2}`}>
            Inventory #&nbsp;
            {inventorynum}&nbsp;is Currently in neighbouring store #&nbsp;
            {neighbouringStoreNumber}{' '}
          </Typography>

          <Typography className={`${classes.popupTextStyle} ${classes.mb2}`}>
            Neighbouring store phone :&nbsp;
            {workPhoneNumber}
          </Typography>

          <Typography className={`${classes.popupTextStyle} ${classes.mb2}`}>
            The Inventory item must be transferred to your store to complete
            agreement creation.
          </Typography>
        </Grid>

        <Grid className={classes.btnlocation}>
          <RACButton
            autoFocus
            size="small"
            variant="contained"
            color="primary"
            isRounded={false}
            style={{ textTransform: 'none' }}
            onClick={() => ok_Transfer_Popup()}
            data-testid="okUnreserve2"
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };


  const unreservePopupChild = () => {
    debugger
    if (salesLeadInfo[0]?.storeNumber != window?.sessionStorage?.getItem('storeNumber')) {

      console.log('salesLeadInfo[0]?.storeNumber',salesLeadInfo[0]?.storeNumber)
      return (
        <>
          <div>
            {loaderforgeopool ?

              <Grid>
                <CircularProgress />
              </Grid> :

              <Grid>
                <Grid
                  className={`${classes.RACcardlg12} ${classes.px2} ${classes.ms1}  ${classes.me1}`}
                >
                  <Typography className={`${classes.popupTextStyle} ${classes.mb2}`}>
                    Sales Lead with {inventorynum} will be auto transferred to your store.
                  </Typography>

                  <Typography className={`${classes.popupTextStyle} ${classes.mb2}`}>
                    You will have to make arrangements to pick up the inventory located at store {neighbouringStoreNumber} Neighbouring store phone : {workPhoneNumber}
                  </Typography>

                  <Typography className={`${classes.popupTextStyle} ${classes.mb2}`}>
                    Do you want to continue?
                  </Typography>
                </Grid>

                <Grid className={classes.btnlocation}>
                  <RACButton
                    autoFocus
                    size="small"
                    // disabled={hidevoid}
                    variant="contained"
                    color="primary"
                    isRounded={false}
                    style={{ textTransform: 'none' }}
                    onClick={() => okUnreservedPopup1()}
                    data-testid="okUnreserve2"
                  >
                    OK
                  </RACButton>
                </Grid>
              </Grid>
            }

          </div>
        </>
      );
    }
    else {
      okUnreservedPopuphomestore()
    }
  };
  const conditionalApprovalChildren = () => {
    return (
      <Grid>
        <Grid
          className={`${classes.RACcardlg12} ${classes.px2} ${classes.ms1}  ${classes.me1} `}
        >
          <Typography className={`${classes.popupTextStyle} ${classes.mb2}`}>
            {' '}
            The customer&apos;s last Decision Engine result was a conditional
            approval.
          </Typography>
          <Typography className={`${classes.popupTextStyle} ${classes.mb2}`}>
            {' '}
            Please consult your operational procedures for guidance.
          </Typography>
        </Grid>
        <Grid className={classes.btnlocation}>
          <RACButton
            autoFocus
            size="small"
            // disabled={hidevoid} onClick={() => ok_approval_Popup()}
            variant="contained"
            color="primary"
            isRounded={false}
            style={{ textTransform: 'none' }}
            onClick={() => ok_approval_Popup()}
            data-testid="okUnreserve2"
          >
            OK
          </RACButton>
        </Grid>{' '}
      </Grid>
    );
  };

  const customerTypeTag = (customerType: string, colorId: number) => {
    return (
      <Chip label={customerType} style={colorId == 2 ? {
        backgroundColor:`#3CAFFF`, color:'whitesmoke', fontWeight: 'bolder', width: '90%'
        } : { backgroundColor:`#AF47D2`, color:'whitesmoke', fontWeight: 'bolder', width: '90%' }}></Chip>
    );
  }

  const getCustomerType = (customerType: any) => {
    return {
      customerType : customerType?.approvalVerifyCode == 0 || customerType?.approvalVerifyCode == 1 ? 'Web - New' : 'Web - Previous',
      colorId : customerType?.approvalVerifyCode == 0 || customerType?.approvalVerifyCode == 1 ? 1 : 2
    }
  }

  return (
    <>
      {masterLoader ? (
        <Grid className={classes.masterLoader}>
          <Grid className={classes.Loader}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}

      <Grid item className={` ${classes.w100}`}>
        <Card
          className={`${classes.RACcardsales} ${classes.RACcardHeight} ${classes.card}`}
        >
          <CardContent>
            <Grid container>
              <Grid item md={12} className={classes.popuptitle}>
                <Typography
                  variant="h6"
                  className={`${classes.title} ${classes.spaceTitle}`}
                >
                  Sales Leads
                </Typography>
              </Grid>

              {

                Status !== '200' && loader1 == false ? (
                  <Grid className={classes.textcenterNorecordsSomething}>
                    <AlertIcon className={classes.racErrorIcon}>{''}</AlertIcon>{' '}
                    Something went Wrong!
                  </Grid>
                ) : Object.keys(mappedData).length === 0 && loader1 === true ? (
                  <Grid
                    className={`${classes.RACloaderNorecords} ${classes.textcenter} ${classes.w100}`}
                  >
                    <CircularProgress />
                  </Grid>
                ) :
                  Object.keys(mappedData).length === 0 && loader1 == false ? (
                    <Grid className={classes.textSpaceNorecords}>
                      <Grid className={classes.textcenterNorecords}>
                        {' '}
                        <InfoIcon className={classes.racErrorIcon}></InfoIcon>
                      </Grid>
                      <Grid className={classes.textcenterImage}>
                        No Records Found
                      </Grid>
                    </Grid>
                  ) : Object.keys(mappedData).length !== 0 ? (
                    <Grid
                      item
                      md={12}
                      sm={12}
                      key={'listgroupkey1'}
                      className={classes.fixedheadertableonline}
                    >
                      <Expandicon
                        className={classes.RACexpandIcon}
                        data-bs-toggle="modal"
                        data-bs-target="#salesleads"
                        onClick={() => expandSaleslead()}
                        data-testid="expandonlineClose"
                      ></Expandicon>
                      <RACTable className={`${classes1.salesGrid}`}
                        renderTableHead={buildSalesLeadHead}
                        renderTableContent={buildSalesGrid}
                        stickyHeader={true}
                      />
                    </Grid>
                  ) : null
              }
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <RACModalCard
        isOpen={expandSale}
        maxWidth="xl"
        onClose={() => cancelPopUp()}
        closeIcon={false}
        dialogOptionalProps={{ open: expandSale, disableBackdropClick: false }}
        children={ExpandSalesPopdiv()}
        title=" Sales Lead"
        borderRadius="15px"
      />

      {/*unreservepopup small modal   popupUnreserved2*/}

      <RACModalCard
        isOpen={popupUnreserved2}
        maxWidth="sm"
        onClose={() => cancelPopUp()}
        closeIcon={true}

        children={UnreservedPopupChildrenSmall()}
        title=" Inventory Unreserved"
        className={classes.title}
        TitleClassName={classes.title}
        borderRadius="15px"
      />

      {/*Transfer Require popup modal*/}
      <RACModalCard
        isOpen={transferPopup}
        maxWidth="sm"
        onClose={() => cancelPopUp()}
        closeIcon={true}

        children={transferRequiredChildren()}
        title=" Inventory Transfer Required"
        className={classes.title}
        borderRadius="15px"
      />

      {/*unreservepopup  modal*/}
      <RACModalCard
        maxWidth="sm"
        isOpen={popupUnreserved1}
        onClose={() => cancelPopUp()}
        closeIcon={true}

        children={unreservePopupChild()}
        title=" Inventory Unreserved"
        className={classes.title}
        borderRadius="15px"
      />

      {/*conditional approval modal*/}
      <RACModalCard
        isOpen={AppprovalPopup}
        maxWidth="sm"
        data-testid="iuu"
        onClose={() => cancelPopUp()}
        closeIcon={true}

        children={conditionalApprovalChildren()}
        title="Customer Notice"
        className={classes.title}
        borderRadius="15px"
      />
    </>
  );
}
